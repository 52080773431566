// Copyright 2024 Merit International Inc. All Rights Reserved

import type { FieldLevelPredicate } from "./types";

type PredicateOption = { readonly label: string; readonly value: FieldLevelPredicate };

// TODO@tyler: Maybe this should be in the schema
// Defined by platform. This is the number of CompoundAtoms allowed in a formula
export const MAX_NUM_RULES = 100;

export const DatePredicateOptions: readonly PredicateOption[] = [
  { label: "is after", value: "AfterThisDate" },
  { label: "is after today", value: "AfterToday" },
  { label: "is before", value: "BeforeThisDate" },
  { label: "is before today", value: "BeforeToday" },
  { label: "is before today minus", value: "BeforeTodayMinusXDays" },
  { label: "equals", value: "EqualToDate" },
];

export const DateTimePredicateOptions: readonly PredicateOption[] = [
  { label: "is after", value: "AfterThisDatetime" },
  { label: "is after time", value: "AfterThisTimeOfDay" },
  { label: "is after now", value: "AfterNow" },
  { label: "is before", value: "BeforeThisDatetime" },
  { label: "is before time", value: "BeforeThisTimeOfDay" },
  { label: "is before now", value: "BeforeNow" },
  { label: "is before now minus", value: "BeforeNowMinusXDuration" },
];

export const EmailPredicateOptions: readonly PredicateOption[] = [
  { label: "has domain", value: "IsEmailDomain" },
];

export const BoolPredicateOptions: readonly PredicateOption[] = [
  { label: "is true", value: "IsTrue" },
  { label: "is false", value: "IsFalse" },
];

export const TextPredicateOptions: readonly PredicateOption[] = [
  { label: "matches", value: "MatchesThisString" },
];

export const NumberPredicateOptions: readonly PredicateOption[] = [
  { label: "is more than", value: "MoreThanX" },
  { label: "is less than", value: "LessThanX" },
  { label: "equals", value: "EqualToX" },
];

export const DefaultPredicateOptions: readonly PredicateOption[] = [
  { label: "has value", value: "FieldHasValue" },
];

export const PredicateNeedsValueSet = new Set<FieldLevelPredicate>([
  "AfterThisDate",
  "AfterThisDatetime",
  "AfterThisTimeOfDay",
  "BeforeThisDate",
  "BeforeTodayMinusXDays",
  "BeforeNowMinusXDuration",
  "BeforeThisDatetime",
  "BeforeThisTimeOfDay",
  "EqualToDate",
  "EqualToX",
  "IsEmailDomain",
  "LessThanX",
  "MatchesThisString",
  "MoreThanX",
]);
