// Copyright 2024 Merit International Inc. All Rights Reserved

/* eslint-disable require-unicode-regexp */

import type { Mask } from "react-native-mask-input";

export const DateMask: Mask = (text = "") => {
  const cleanText = text.replace(/\D+/g, "");

  const getMonthSecondDigitMask = (str: string) => {
    if (str.charAt(4) === "0") {
      return /[1-9]/;
    }

    if (str.charAt(4) === "1") {
      return /[012]/;
    }

    return /\d/;
  };

  const getDaySecondDigitMask = (str: string) => {
    if (str.charAt(6) === "0") {
      return /[1-9]/;
    }

    if (str.charAt(6) === "3") {
      return /[01]/;
    }

    return /\d/;
  };

  return [
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /[0-1]/,
    getMonthSecondDigitMask(cleanText),
    "-",
    /[0-3]/,
    getDaySecondDigitMask(cleanText),
  ];
};

export const TimeMask = (text = "") => {
  const cleanText = text.replace(/\D+/g, "");

  // eslint-disable-next-line functional/no-let
  const getHourSecondDigitMask = (str: string) => {
    if (["0", "1"].includes(str.charAt(0))) {
      return /[1-9]/;
    }

    if (str.startsWith("2")) {
      return /[0-3]/;
    }

    return /\d/;
  };

  return [/[0-2]/, getHourSecondDigitMask(cleanText), ":", /[0-5]/, /\d/, ":", /[0-5]/, /\d/];
};

export const DateTimeMask: Mask = (text = "") => {
  const dateLength = DateMask().length;

  return [...DateMask(text.slice(0, dateLength)), "T", ...TimeMask(text.slice(dateLength))];
};
