// Copyright 2024 Merit International Inc. All Rights Reserved

import { Heading } from "@merit/frontend-components";
import { Image, StyleSheet, View } from "react-native";
import { Images } from "../utils/Image";
import { VerticalSpacer } from "../components/Spacer";
import { useGetTestProps } from "@src/hooks/useGetTestProps";
import React from "react";

const styles = StyleSheet.create({
  centerContent: { alignItems: "center", justifyContent: "center" },
});

export const NoAccess = () => {
  const testProps = useGetTestProps();

  return (
    <View style={{ flex: 1 }}>
      <View style={[styles.centerContent, { flex: 1 }]}>
        <Image accessibilityLabel="lock" source={Images.lock} style={{ height: 120, width: 120 }} />
        <VerticalSpacer />
        <Heading level="1" {...testProps({ elementName: "noAccessBody" })}>
          You do not have the correct permissions to access this page
        </Heading>
      </View>
    </View>
  );
};
