import {
  OrgAddOnNames,
  useAppConstantsStore,
  useAuthStore,
  useConfigurationStore,
} from "@src/stores";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useMemo } from "react";
import { useOrgAddons } from "@src/hooks/useOrgAddOns";
import { useOrgAllowedAdminRoles } from "./useOrgAllowedAdminRoles";
import type { AdminRoleNamesValue } from "@src/stores";
import type { LDFeatureFlags } from "@src/configuration/featureFlags";

export const useLoggedInUserRoles = () => {
  // Not using useLoggedInAuthState because of the asserts and this hook may be
  // used in the Router component, where there are code branches that the user is
  // not logged in
  const { profile, selectedOrgId } = useAuthStore();
  const { adminRoleNames } = useAppConstantsStore();
  const { rolloutAdminPortalAuth0OrgAuth } = useFlags<LDFeatureFlags>();
  const configStore = useConfigurationStore();
  const { roles: orgAllowedRoles } = useOrgAllowedAdminRoles();
  const { addons } = useOrgAddons();

  return useMemo(() => {
    const userRoles = profile === null ? [] : profile.userRoles;
    if (!rolloutAdminPortalAuth0OrgAuth) {
      return {
        isCheckinAdmin: true,
        isDashboardAdmin: true,
        isDesignAdmin: true,
        isOrgAdmin: true,
        userRoles, // this is only used for display purposes, it's better to leave it empty when the user has no roles.
      };
    }
    const isSol = selectedOrgId === configStore.configuration?.solUUID;
    const filteredUserRoles =
      selectedOrgId === configStore.configuration?.solUUID
        ? userRoles
        : userRoles.filter(role => orgAllowedRoles.includes(role as AdminRoleNamesValue));

    const isOrgAdmin = Boolean(filteredUserRoles.includes(adminRoleNames.orgAdmin));
    const orgHasAddon = (addonName: string): boolean => isSol || addons.includes(addonName);

    return {
      isCheckinAdmin: Boolean(
        filteredUserRoles.includes(adminRoleNames.checkinAdmin) ||
          (isOrgAdmin && orgHasAddon(OrgAddOnNames.checkIn))
      ),
      isDashboardAdmin: Boolean(
        filteredUserRoles.includes(adminRoleNames.dashboardAdmin) ||
          (isOrgAdmin && orgHasAddon(OrgAddOnNames.dashboardAndAnalytics))
      ),
      isDesignAdmin: Boolean(
        filteredUserRoles.includes(adminRoleNames.designAdmin) ||
          (isOrgAdmin && orgHasAddon(OrgAddOnNames.studio))
      ),
      isOrgAdmin,
      userRoles,
    };
  }, [
    addons,
    adminRoleNames.checkinAdmin,
    adminRoleNames.dashboardAdmin,
    adminRoleNames.designAdmin,
    adminRoleNames.orgAdmin,
    configStore.configuration?.solUUID,
    orgAllowedRoles,
    profile,
    rolloutAdminPortalAuth0OrgAuth,
    selectedOrgId,
  ]);
};
